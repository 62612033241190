._5ozg8c3 {
  transition: var(--_5ozg8c0);
}
._5ozg8c7 {
  transform: translateX(100%);
}
._5ozg8cd {
  background-color: var(--stackflow-plugin-basic-ui-dim-background-color);
  z-index: var(--_5ozg8c1);
  opacity: 0;
}
._5ozg8c4 ._5ozg8cd, ._5ozg8c5 ._5ozg8cd {
  opacity: 1;
}
._5ozg8c6 ._5ozg8cd, ._5ozg8c7 ._5ozg8cd {
  opacity: 0;
}
._5ozg8ce {
  background-color: var(--stackflow-plugin-basic-ui-background-color);
  width: 100%;
  margin: 0 2.5rem;
  box-shadow: 0px 0.625rem 2.375rem rgba(0, 0, 0, 0.15), 0px .5625rem 2.875rem rgba(0, 0, 0, 0.12), 0px .3125rem .9375rem rgba(0, 0, 0, 0.1);
  border-radius: var(--stackflow-plugin-basic-ui-bottom-sheet-border-radius);
  transform: scale(1.1);
  opacity: 0;
}
._5ozg8c4 ._5ozg8ce, ._5ozg8c5 ._5ozg8ce {
  transform: scale(1);
  opacity: 1;
}
._5ozg8c6 ._5ozg8ce, ._5ozg8c7 ._5ozg8ce {
  transform: scale(1.1);
  opacity: 0;
}