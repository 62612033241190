.seed-alert-dialog[data-part="backdrop"] {
  position: fixed;
  inset: 0;
  z-index: 1000;

  background: var(--seed-alert-dialog-backdrop-background);
}

.seed-alert-dialog[data-part="positioner"] {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  inset: 0;

  width: 100vw;
  height: 100vh;

  z-index: 1000;
}

.seed-alert-dialog[data-part="content"] {
  display: flex;
  flex: 1;
  flex-direction: column;

  box-sizing: border-box;

  word-break: break-all;

  gap: var(--seed-alert-dialog-containers-gap);

  max-width: var(--seed-alert-dialog-content-max-width);
  border-radius: var(--seed-alert-dialog-content-border-radius);

  background: var(--seed-alert-dialog-content-background);

  margin: auto var(--seed-alert-dialog-content-horizontal-margin);
}

.seed-alert-dialog[data-part="title"] {
  margin: 0;

  color: var(--seed-alert-dialog-title-color);
}

.seed-alert-dialog[data-part="description"] {
  margin: 0;
  white-space: pre-wrap;

  color: var(--seed-alert-dialog-description-color);
}

.seed-alert-dialog[data-part="text-container"] {
  display: flex;
  flex-direction: column;

  padding-top: var(--seed-alert-dialog-text-container-padding-top);
  padding-left: var(--seed-alert-dialog-text-container-horizontal-padding);
  padding-right: var(--seed-alert-dialog-text-container-horizontal-padding);

  gap: var(--seed-alert-dialog-texts-gap);
}

.seed-alert-dialog[data-part="button-container"] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;

  padding-bottom: var(--seed-alert-dialog-button-container-padding-bottom);
  padding-left: var(--seed-alert-dialog-button-container-horizontal-padding);
  padding-right: var(--seed-alert-dialog-button-container-horizontal-padding);

  gap: var(--seed-alert-dialog-buttons-gap);
}
.seed-alert-dialog[data-part="button-container"][data-action-intent="alternative"] {
  flex-direction: row-reverse;
}
.seed-alert-dialog[data-part="button-container"][data-action-intent="neutral"] {
  flex-direction: row-reverse;

  /*
    Intentionally overriding the background of the SecondaryAction because
    the variants of `AlertDialog` and `SecondaryAction` have the same background
    with `seed-scale-gray.100` when in variant is `neutral` and `dark mode`.
  */
  --seed-box-button-secondary-enabled-background: var(
    --seed-alert-dialog-secondary-action-neutral-background
  );
}

.seed-alert-dialog[data-part="button-container"] > button.seed-box-button {
  flex-grow: 1;

  width: initial;
  min-width: calc(50% - var(--seed-alert-dialog-buttons-gap) / 2);
}

.seed-alert-dialog[data-part="button-container"] > button [data-part="content"] {
  width: 100%;
}
/* If the text overflows after the button is automatically vertically aligned, the text will be truncated. */
.seed-alert-dialog[data-part="button-container"] > button [data-part="content"] > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.seed-alert-dialog[data-part="button-container"][data-action-intent="nonpreferred"] {
  flex-direction: column;
}

.seed-alert-dialog[data-part="button-container"][data-action-intent="nonpreferred"]
  > button {
  width: 100%;
}

.seed-alert-dialog[data-part="button-container"][data-action-intent="nonpreferred"]
  > button.seed-text-button {
  color: var(--seed-alert-dialog-nonpreferred-second-action-color);
}
