/* BoxButton */
.seed-box-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-decoration: none;
}

.seed-box-button [data-part="content"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
}

.seed-box-button[data-loading] [data-part="content"] {
  opacity: 0;
}

.seed-box-button [data-part="spinner-container"] {
  position: absolute;
  
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.seed-box-button[data-disabled] {
  cursor: not-allowed;
}

.seed-box-button[data-focus]:not([data-focus-visible]) {
  outline: none;
}

.seed-box-button[data-focus][data-focus-visible] {
  outline: var(--seed-box-button-focus-border);
}

.seed-box-button[data-size="xlarge"] {
  height: var(--seed-box-button-xlarge-height);
  padding: 0 var(--seed-box-button-xlarge-padding-x);
  border-radius: var(--seed-box-button-xlarge-border-radius);
}
.seed-box-button[data-size="xlarge"] [data-part="content"] {
  gap: var(--seed-box-button-xlarge-gap);
}

.seed-box-button[data-size="large"] {
  height: var(--seed-box-button-large-height);
  padding: 0 var(--seed-box-button-large-padding-x);
  border-radius: var(--seed-box-button-large-border-radius);
}
.seed-box-button[data-size="large"] [data-part="content"] {
  gap: var(--seed-box-button-large-gap);
}

.seed-box-button[data-size="medium"] {
  height: var(--seed-box-button-medium-height);
  padding: 0 var(--seed-box-button-medium-padding-x);
  border-radius: var(--seed-box-button-medium-border-radius);
}
.seed-box-button[data-size="medium"] [data-part="content"] {
  gap: var(--seed-box-button-medium-gap);
}

.seed-box-button[data-size="small"] {
  height: var(--seed-box-button-small-height);
  padding: 0 var(--seed-box-button-small-padding-x);
  border-radius: var(--seed-box-button-small-border-radius);
}
.seed-box-button[data-size="small"] [data-part="content"] {
  gap: var(--seed-box-button-small-gap);
}

.seed-box-button[data-size="xsmall"] {
  height: var(--seed-box-button-xsmall-height);
  padding: 0 var(--seed-box-button-xsmall-padding-x);
  border-radius: var(--seed-box-button-xsmall-border-radius);
}
.seed-box-button[data-size="xsmall"] [data-part="content"] {
  gap: var(--seed-box-button-xsmall-gap);
}

.seed-box-button[data-variant="primary"] {
  background-color: var(--seed-box-button-primary-enabled-background);
  color: var(--seed-box-button-primary-enabled-color);
}

.seed-box-button[data-variant="primary"][data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-box-button-primary-hovered-background);
  color: var(--seed-box-button-primary-hovered-color);
}

.seed-box-button[data-variant="primary"][data-pressed]:not([data-disabled]) {
  background-color: var(--seed-box-button-primary-pressed-background);
  color: var(--seed-box-button-primary-pressed-color);
}

.seed-box-button[data-variant="primary"][data-disabled] {
  background-color: var(--seed-box-button-primary-disabled-background);
  color: var(--seed-box-button-primary-disabled-color);
}

.seed-box-button[data-variant="primaryLow"] {
  background-color: var(--seed-box-button-primary-low-enabled-background);
  color: var(--seed-box-button-primary-low-enabled-color);
}

.seed-box-button[data-variant="primaryLow"][data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-box-button-primary-low-hovered-background);
  color: var(--seed-box-button-primary-low-hovered-color);
}

.seed-box-button[data-variant="primaryLow"][data-pressed]:not([data-disabled]) {
  background-color: var(--seed-box-button-primary-low-pressed-background);
  color: var(--seed-box-button-primary-low-pressed-color);
}

.seed-box-button[data-variant="primaryLow"][data-disabled] {
  background-color: var(--seed-box-button-primary-low-disabled-background);
  color: var(--seed-box-button-primary-low-disabled-color);
}

.seed-box-button[data-variant="secondary"] {
  background-color: var(--seed-box-button-secondary-enabled-background);
  color: var(--seed-box-button-secondary-enabled-color);
}

.seed-box-button[data-variant="secondary"][data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-box-button-secondary-hovered-background);
  color: var(--seed-box-button-secondary-hovered-color);
}

.seed-box-button[data-variant="secondary"][data-pressed]:not([data-disabled]) {
  background-color: var(--seed-box-button-secondary-pressed-background);
  color: var(--seed-box-button-secondary-pressed-color);
}

.seed-box-button[data-variant="secondary"][data-disabled] {
  background-color: var(--seed-box-button-secondary-disabled-background);
  color: var(--seed-box-button-secondary-disabled-color);
}

.seed-box-button[data-variant="danger"] {
  background-color: var(--seed-box-button-danger-enabled-background);
  color: var(--seed-box-button-danger-enabled-color);
}

.seed-box-button[data-variant="danger"][data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-box-button-danger-hovered-background);
  color: var(--seed-box-button-danger-hovered-color);
}

.seed-box-button[data-variant="danger"][data-pressed]:not([data-disabled]) {
  background-color: var(--seed-box-button-danger-pressed-background);
  color: var(--seed-box-button-danger-pressed-color);
}

.seed-box-button[data-variant="danger"][data-disabled] {
  background-color: var(--seed-box-button-danger-disabled-background);
  color: var(--seed-box-button-danger-disabled-color);
}

/* Icon */
.seed-box-button [data-part="prefix"],
.seed-box-button [data-part="suffix"] {
  display: inline-flex;
}

.seed-box-button[data-size="xlarge"] [data-part="prefix"] {
  width: var(--seed-box-button-xlarge-prefix-size);
  height: var(--seed-box-button-xlarge-prefix-size);
}

.seed-box-button[data-size="large"] [data-part="prefix"] {
  width: var(--seed-box-button-large-prefix-size);
  height: var(--seed-box-button-large-prefix-size);
}

.seed-box-button[data-size="medium"] [data-part="prefix"] {
  width: var(--seed-box-button-medium-prefix-size);
  height: var(--seed-box-button-medium-prefix-size);
}

.seed-box-button[data-size="small"] [data-part="prefix"] {
  width: var(--seed-box-button-small-prefix-size);
  height: var(--seed-box-button-small-prefix-size);
}

.seed-box-button[data-size="xsmall"] [data-part="prefix"] {
  width: var(--seed-box-button-xsmall-prefix-size);
  height: var(--seed-box-button-xsmall-prefix-size);
}

.seed-box-button[data-size="xlarge"] [data-part="suffix"] {
  width: calc(var(--seed-box-button-xlarge-suffix-size) - var(--seed-box-button-xlarge-suffix-bleed) * 2);
  height: var(--seed-box-button-xlarge-suffix-size);
}

.seed-box-button[data-size="xlarge"] [data-part="suffix"]>* {
  margin-left: calc(var(--seed-box-button-xlarge-suffix-bleed) * -1);
  margin-right: calc(var(--seed-box-button-xlarge-suffix-bleed) * -1);
}

.seed-box-button[data-size="large"] [data-part="suffix"] {
  width: calc(var(--seed-box-button-large-suffix-size) - var(--seed-box-button-large-suffix-bleed) * 2);
  height: var(--seed-box-button-large-suffix-size);
}

.seed-box-button[data-size="large"] [data-part="suffix"] > * {
  margin-left: calc(var(--seed-box-button-large-suffix-bleed) * -1);
  margin-right: calc(var(--seed-box-button-large-suffix-bleed) * -1);
}

.seed-box-button[data-size="medium"] [data-part="suffix"] {
  width: calc(var(--seed-box-button-medium-suffix-size) - var(--seed-box-button-medium-suffix-bleed) * 2);
  height: var(--seed-box-button-medium-suffix-size);
}

.seed-box-button[data-size="medium"] [data-part="suffix"]>* {
  margin-left: calc(var(--seed-box-button-medium-suffix-bleed) * -1);
  margin-right: calc(var(--seed-box-button-medium-suffix-bleed) * -1);
}

.seed-box-button[data-size="small"] [data-part="suffix"] {
  width: calc(var(--seed-box-button-small-suffix-size) - var(--seed-box-button-small-suffix-bleed) * 2);
  height: var(--seed-box-button-small-suffix-size);
}

.seed-box-button[data-size="small"] [data-part="suffix"]>* {
  margin-left: calc(var(--seed-box-button-small-suffix-bleed) * -1);
  margin-right: calc(var(--seed-box-button-small-suffix-bleed) * -1);
}

.seed-box-button[data-size="xsmall"] [data-part="suffix"] {
  width: calc(var(--seed-box-button-xsmall-suffix-size) - var(--seed-box-button-xsmall-suffix-bleed) * 2);
  height: var(--seed-box-button-xsmall-suffix-size);
}

.seed-box-button[data-size="xsmall"] [data-part="suffix"]>* {
  margin-left: calc(var(--seed-box-button-xsmall-suffix-bleed) * -1);
  margin-right: calc(var(--seed-box-button-xsmall-suffix-bleed) * -1);
}

/* CapsuleButton */
.seed-capsule-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  text-transform: none;
  text-decoration: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.seed-capsule-button [data-part="content"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
}

.seed-capsule-button[data-loading] [data-part="content"] {
  opacity: 0;
}

.seed-capsule-button [data-part="spinner-container"] {
  position: absolute;
  
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.seed-capsule-button[data-stretch="true"] {
  flex: 1;
}

.seed-capsule-button[data-disabled] {
  cursor: not-allowed;
}

.seed-capsule-button[data-focus]:not([data-focus-visible]) {
  outline: none;
}

.seed-capsule-button[data-focus][data-focus-visible] {
  outline: var(--seed-capsule-button-focus-border);
}

/* Radii */
.seed-capsule-button {
  border-radius: var(--seed-capsule-button-border-radius);
}

/* Size */
.seed-capsule-button[data-size="small"] {
  height: var(--seed-capsule-button-small-height);
  padding: 0 var(--seed-capsule-button-small-padding-x);
}

.seed-capsule-button[data-size="xsmall"] {
  height: var(--seed-capsule-button-xsmall-height);
  padding: 0 var(--seed-capsule-button-xsmall-padding-x);
}

/* Color */
.seed-capsule-button[data-variant="primaryLow"] {
  background-color: var(--seed-capsule-button-primary-low-enabled-background);
  color: var(--seed-capsule-button-primary-low-enabled-color);
}

.seed-capsule-button[data-variant="primaryLow"][data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-capsule-button-primary-low-hovered-background);
  color: var(--seed-capsule-button-primary-low-hovered-color);
}

.seed-capsule-button[data-variant="primaryLow"][data-pressed]:not([data-disabled]) {
  background-color: var(--seed-capsule-button-primary-low-pressed-background);
  color: var(--seed-capsule-button-primary-low-pressed-color);
}

.seed-capsule-button[data-variant="primaryLow"][data-disabled] {
  background-color: var(--seed-capsule-button-primary-low-disabled-background);
  color: var(--seed-capsule-button-primary-low-disabled-color);
}

.seed-capsule-button[data-variant="secondary"] {
  background-color: var(--seed-capsule-button-secondary-enabled-background);
  color: var(--seed-capsule-button-secondary-enabled-color);
}

.seed-capsule-button[data-variant="secondary"][data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-capsule-button-secondary-hovered-background);
  color: var(--seed-capsule-button-secondary-hovered-color);
}

.seed-capsule-button[data-variant="secondary"][data-pressed]:not([data-disabled]) {
  background-color: var(--seed-capsule-button-secondary-pressed-background);
  color: var(--seed-capsule-button-secondary-pressed-color);
}

.seed-capsule-button[data-variant="secondary"][data-disabled] {
  background-color: var(--seed-capsule-button-secondary-disabled-background);
  color: var(--seed-capsule-button-secondary-disabled-color);
}

/* TextButton */
.seed-text-button {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: unset;
  text-decoration: none;
}

.seed-text-button[data-disabled] {
  cursor: not-allowed;
}

.seed-text-button[data-focus]:not([data-focus-visible]) {
  outline: none;
}

.seed-text-button[data-focus][data-focus-visible] {
  outline: var(--seed-text-button-focus-border);
}

/* Size */
/* size=large */
.seed-text-button[data-size="large"] {
  height: var(--seed-text-button-large-height);
}

/* size=medium */
.seed-text-button[data-size="medium"] {
  height: var(--seed-text-button-medium-height);
}

/* size=small */
.seed-text-button[data-size="small"] {
  height: var(--seed-text-button-small-height);
}

/* Variant */
/* variant=primary */
.seed-text-button[data-variant="primary"] {
  color: var(--seed-text-button-primary-color);
}

.seed-text-button[data-variant="primary"][data-disabled] {
  color: var(--seed-text-button-primary-disabled-color);
}

.seed-text-button[data-variant="primary"][data-hover] {
  color: var(--seed-text-button-primary-hovered-color);
}

.seed-text-button[data-variant="primary"][data-pressed] {
  color: var(--seed-text-button-primary-pressed-color);
}

/* variant=secondary */
.seed-text-button[data-variant="secondary"][data-disabled] {
  color: var(--seed-text-button-secondary-disabled-color);
}

.seed-text-button[data-variant="secondary"] {
  color: var(--seed-text-button-secondary-color);
}

.seed-text-button[data-variant="secondary"][data-hover] {
  color: var(--seed-text-button-secondary-hovered-color);
}

.seed-text-button[data-variant="secondary"][data-pressed] {
  color: var(--seed-text-button-secondary-pressed-color);
}

/* variant=secondaryLow */
.seed-text-button[data-variant="secondaryLow"] {
  color: var(--seed-text-button-secondary-low-color);
}

.seed-text-button[data-variant="secondaryLow"][data-disabled] {
  color: var(--seed-text-button-secondary-low-disabled-color);
}

.seed-text-button[data-variant="secondaryLow"][data-hover] {
  color: var(--seed-text-button-secondary-low-hovered-color);
}

.seed-text-button[data-variant="secondaryLow"][data-pressed] {
  color: var(--seed-text-button-secondary-low-pressed-color);
}

/* variant=accent */
.seed-text-button[data-variant="accent"] {
  color: var(--seed-text-button-accent-color);
}

.seed-text-button[data-variant="accent"][data-disabled] {
  color: var(--seed-text-button-accent-disabled-color);
}

.seed-text-button[data-variant="accent"][data-hover] {
  color: var(--seed-text-button-accent-hovered-color);
}

.seed-text-button[data-variant="accent"][data-pressed] {
  color: var(--seed-text-button-accent-pressed-color);
}

/* variant=danger */
.seed-text-button[data-variant="danger"][data-disabled] {
  color: var(--seed-text-button-danger-disabled-color);
}

.seed-text-button[data-variant="danger"] {
  color: var(--seed-text-button-danger-color);
}

.seed-text-button[data-variant="danger"][data-hover] {
  color: var(--seed-text-button-danger-hovered-color);
}

.seed-text-button[data-variant="danger"][data-pressed] {
  color: var(--seed-text-button-danger-pressed-color);
}

.seed-text-button[data-reset-padding] {
  padding: 0;
}

/* Style */
/* style=basic */
.seed-text-button[data-style="basic"] {
  text-decoration: none;
}

/* style=underlined */
.seed-text-button[data-style="underlined"] {
  text-decoration: underline;
  text-underline-offset: 4px;
}

/* Icon */
.seed-text-button [data-part="prefix"],
.seed-text-button [data-part="suffix"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.seed-text-button[data-size="large"] [data-part="prefix"] {
  margin-right: var(--seed-text-button-large-content-icon-gap);
}
.seed-text-button[data-size="medium"] [data-part="prefix"] {
  margin-right: var(--seed-text-button-medium-content-icon-gap);
}
.seed-text-button[data-size="small"] [data-part="prefix"] {
  margin-right: var(--seed-text-button-small-content-icon-gap);
}

.seed-text-button[data-size="large"] [data-part="suffix"] {
  margin-left: var(--seed-text-button-large-content-icon-gap);
}
.seed-text-button[data-size="medium"] [data-part="suffix"] {
  margin-left: var(--seed-text-button-medium-content-icon-gap);
}
.seed-text-button[data-size="small"] [data-part="suffix"] {
  margin-left: var(--seed-text-button-small-content-icon-gap);
}

.seed-text-button[data-size="large"] [data-part="prefix"] {
  width: var(--seed-text-button-large-prefix-size);
  height: var(--seed-text-button-large-prefix-size);
}
.seed-text-button[data-size="medium"] [data-part="prefix"] {
  width: var(--seed-text-button-medium-prefix-size);
  height: var(--seed-text-button-medium-prefix-size);
}
.seed-text-button[data-size="small"] [data-part="prefix"] {
  width: var(--seed-text-button-small-prefix-size);
  height: var(--seed-text-button-small-prefix-size);
}

.seed-text-button[data-size="large"] [data-part="suffix"] {
  width: var(--seed-text-button-large-suffix-size);
  height: var(--seed-text-button-large-suffix-size);
}
.seed-text-button[data-size="medium"] [data-part="suffix"] {
  width: var(--seed-text-button-medium-suffix-size);
  height: var(--seed-text-button-medium-suffix-size);
}
.seed-text-button[data-size="small"] [data-part="suffix"] {
  width: var(--seed-text-button-small-suffix-size);
  height: var(--seed-text-button-small-suffix-size);
}
