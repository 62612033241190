@import-normalize;

@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/NotoSansKR-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/NotoSansKR-Bold.woff2") format("woff2");
}

* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  line-height: 150%;
  letter-spacing: -0.02em;
}

html {
  color: #212529;
  cursor: default;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  user-select: none;
  -webkit-touch-callout: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overscroll-behavior: contain;
  height: 100%;
}

a {
  color: #212529;
  text-decoration: none;
}

input {
  appearance: none;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
