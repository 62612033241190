:root[data-seed] {
  /* Color */
  --seed-callout-normal-background: var(--seed-scale-color-gray-100);
  --seed-callout-normal-color: var(--seed-scale-color-gray-900);

  --seed-callout-info-background: var(--seed-scale-color-blue-alpha-50);
  --seed-callout-info-color: var(--seed-scale-color-blue-950);

  --seed-callout-warning-background: var(--seed-scale-color-yellow-alpha-50);
  --seed-callout-warning-color: var(--seed-scale-color-yellow-950);

  --seed-callout-danger-background: var(--seed-scale-color-red-alpha-50);
  --seed-callout-danger-color: var(--seed-scale-color-red-950);

  --seed-callout-outline-border-color: var(--seed-scale-color-gray-300);
  --seed-callout-outline-background: var(--seed-scale-color-gray-00);

  --seed-callout-magic-background: var(--seed-scale-color-purple-50);
  --seed-callout-magic-color: var(--seed-scale-color-purple-950);

  --seed-actionable-callout-outline-background: var(--seed-semantic-color-gray-pressed);
  --seed-actionable-callout-normal-background: var(--seed-scale-color-gray-200);
  --seed-actionable-callout-info-background: var(--seed-scale-color-blue-alpha-100);
  --seed-actionable-callout-warning-background: var(--seed-scale-color-yellow-alpha-100);
  --seed-actionable-callout-danger-background: var(--seed-scale-color-red-alpha-100);
  --seed-actionable-callout-magic-background: var(--seed-scale-color-purple-100);

  /* Layout */
  --seed-callout-padding: 14px;
 
  --seed-dismissablecallout-padding-left: 16px;
  --seed-dismissablecallout-padding-right: 14px;
  --seed-actionablecallout-padding-left: 16px;
  --seed-actionablecallout-padding-right: 12px;

  --seed-callout-content-padding-x: 2px;
  --seed-callout-title-description-gap: 8px;

  --seed-callout-border-radius: 10px;
  --seed-callout-icon-size: 16px;
  --seed-callout-icon-margin-right: 8px;

  --seed-callout-action-indicator-size: 18px;
  --seed-callout-action-indicator-margin-left: 10px;
  
  --seed-callout-close-button-size: 18px;
  --seed-callout-close-button-margin-left: 10px;

  /* 수직 위치 보정 */
  --seed-callout-icon-margin-top: 2px;
}
