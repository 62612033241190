.seed-callout {
  display: flex;
  align-items: stretch;
  box-sizing: border-box;

  border: none;
  text-transform: none;
  text-align: start;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  border-radius: var(--seed-callout-border-radius);

  padding: var(--seed-callout-padding);
}

.seed-actionable-callout {
  cursor: pointer;

  padding-left: var(--seed-actionablecallout-padding-left);
  padding-right: var(--seed-actionablecallout-padding-right);
}

.seed-dismissable-callout {
  padding-left: var(--seed-dismissablecallout-padding-left);
  padding-right: var(--seed-dismissablecallout-padding-right);
}

.seed-callout [data-part="content"] {
  flex: 1 1 auto;
  padding: 0 var(--seed-callout-content-padding-x);
}

.seed-callout [data-part="title"] {
  margin-right: var(--seed-callout-title-description-gap);
}

.seed-callout[data-variant="outline"] {
  /* inner border를 위해서 box-shadow로 대체 */
  /* border: 1px solid var(--seed-callout-outline-border-color); */
  box-shadow: 0 0 0 1px var(--seed-callout-outline-border-color);

  color: var(--seed-callout-normal-color);
}

.seed-callout[data-variant="normal"] {
  background-color: var(--seed-callout-normal-background);
  color: var(--seed-callout-normal-color);
}

.seed-callout[data-variant="info"] {
  background-color: var(--seed-callout-info-background);
  color: var(--seed-callout-info-color);
}

.seed-callout[data-variant="warning"] {
  background-color: var(--seed-callout-warning-background);
  color: var(--seed-callout-warning-color);
}

.seed-callout[data-variant="danger"] {
  background-color: var(--seed-callout-danger-background);
  color: var(--seed-callout-danger-color);
}

.seed-callout[data-variant="magic"] {
  background-color: var(--seed-callout-magic-background);
  color: var(--seed-callout-magic-color);
}

.seed-callout [data-part="icon"] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: var(--seed-callout-icon-size);
  height: var(--seed-callout-icon-size);
  margin-right: var(--seed-callout-icon-margin-right);

  /* 수직 위치 보정 */
  margin-top: var(--seed-callout-icon-margin-top);
}

.seed-actionable-callout [data-part="action-indicator"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--seed-callout-action-indicator-size);
  margin-left: var(--seed-callout-action-indicator-margin-left);
}

.seed-actionable-callout[data-variant="outline"][data-pressed] {
  background-color: var(--seed-actionable-callout-outline-background);
}

.seed-actionable-callout[data-variant="normal"][data-pressed] {
  background-color: var(--seed-actionable-callout-normal-background);
}

.seed-actionable-callout[data-variant="info"][data-pressed] {
  background-color: var(--seed-actionable-callout-info-background);
}

.seed-actionable-callout[data-variant="warning"][data-pressed] {
  background-color: var(--seed-actionable-callout-warning-background);
}

.seed-actionable-callout[data-variant="danger"][data-pressed] {
  background-color: var(--seed-actionable-callout-danger-background);
}

.seed-actionable-callout[data-variant="magic"][data-pressed] {
  background-color: var(--seed-actionable-callout-magic-background);
}

.seed-dismissable-callout [data-part="close-button"] {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: var(--seed-callout-close-button-size);
  margin-left: var(--seed-callout-close-button-margin-left);
}
