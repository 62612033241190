:root, :root[data-stackflow-plugin-basic-ui-theme=android] {
  --stackflow-plugin-basic-ui-background-color: #fff;
  --stackflow-plugin-basic-ui-dim-background-color: rgba(0, 0, 0, 0.15);
  --stackflow-plugin-basic-ui-app-bar-border-color: rgba(0, 0, 0, 0.07);
  --stackflow-plugin-basic-ui-app-bar-border-size: 1px;
  --stackflow-plugin-basic-ui-app-bar-height: 3.5rem;
  --stackflow-plugin-basic-ui-app-bar-icon-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-text-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-background-color: #fff;
  --stackflow-plugin-basic-ui-bottom-sheet-border-radius: 1rem;
}
:root[data-stackflow-plugin-basic-ui-theme=cupertino] {
  --stackflow-plugin-basic-ui-background-color: #fff;
  --stackflow-plugin-basic-ui-dim-background-color: rgba(0, 0, 0, 0.15);
  --stackflow-plugin-basic-ui-app-bar-border-color: rgba(0, 0, 0, 0.07);
  --stackflow-plugin-basic-ui-app-bar-border-size: 0.5px;
  --stackflow-plugin-basic-ui-app-bar-height: 2.75rem;
  --stackflow-plugin-basic-ui-app-bar-icon-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-text-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-background-color: #fff;
  --stackflow-plugin-basic-ui-bottom-sheet-border-radius: 1rem;
}
._1coppmz0 {
  --stackflow-plugin-basic-ui-background-color: #fff;
  --stackflow-plugin-basic-ui-dim-background-color: rgba(0, 0, 0, 0.15);
  --stackflow-plugin-basic-ui-app-bar-border-color: rgba(0, 0, 0, 0.07);
  --stackflow-plugin-basic-ui-app-bar-border-size: 1px;
  --stackflow-plugin-basic-ui-app-bar-height: 3.5rem;
  --stackflow-plugin-basic-ui-app-bar-icon-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-text-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-background-color: #fff;
  --stackflow-plugin-basic-ui-bottom-sheet-border-radius: 1rem;
}
._1coppmz1 {
  --stackflow-plugin-basic-ui-background-color: #fff;
  --stackflow-plugin-basic-ui-dim-background-color: rgba(0, 0, 0, 0.15);
  --stackflow-plugin-basic-ui-app-bar-border-color: rgba(0, 0, 0, 0.07);
  --stackflow-plugin-basic-ui-app-bar-border-size: 0.5px;
  --stackflow-plugin-basic-ui-app-bar-height: 2.75rem;
  --stackflow-plugin-basic-ui-app-bar-icon-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-text-color: #212124;
  --stackflow-plugin-basic-ui-app-bar-background-color: #fff;
  --stackflow-plugin-basic-ui-bottom-sheet-border-radius: 1rem;
}