._1yk3a450 {
  background-color: var(--stackflow-plugin-basic-ui-app-bar-background-color);
  height: var(--stackflow-plugin-basic-ui-app-bar-height);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  z-index: var(--_1dlf4344);
}
._1coppmz1 ._1yk3a450, :root[data-stackflow-plugin-basic-ui-theme=cupertino] ._1yk3a450 {
  position: absolute;
}
._1coppmz1 ._1dlf434b ._1yk3a450, :root[data-stackflow-plugin-basic-ui-theme=cupertino] ._1dlf434b ._1yk3a450 {
  transform: translateX(100%);
}
._1coppmz0 ._1yk3a450, :root[data-stackflow-plugin-basic-ui-theme=android] ._1yk3a450 {
  opacity: 0;
  transform: translateY(10rem);
  transition: var(--_1dlf4340);
}
._1coppmz0 ._1dlf4349 ._1yk3a450, :root[data-stackflow-plugin-basic-ui-theme=android] ._1dlf4349 ._1yk3a450 {
  opacity: 1;
  transform: translateY(0);
}
._1coppmz0 ._1dlf434a ._1yk3a450, :root[data-stackflow-plugin-basic-ui-theme=android] ._1dlf434a ._1yk3a450 {
  opacity: 1;
  transform: translateY(0);
}
._1yk3a451 {
  box-shadow: inset 0px calc(var(--stackflow-plugin-basic-ui-app-bar-border-size) * -1) 0 var(--stackflow-plugin-basic-ui-app-bar-border-color);
}
._1yk3a452 {
  padding: 0 0.5rem;
}
._1yk3a452:empty {
  display: none;
}
._1yk3a453 {
  color: var(--stackflow-plugin-basic-ui-app-bar-icon-color);
  transition: opacity 300ms;
  width: 2.25rem;
  height: 2.75rem;
}
._1yk3a453:active {
  opacity: 0.2;
  transition: opacity 0s;
}
._1yk3a455 {
  flex: 1;
}
._1yk3a456 {
  width: var(--_1dlf4345);
  color: var(--stackflow-plugin-basic-ui-app-bar-text-color);
}
._1coppmz0 ._1yk3a456, :root[data-stackflow-plugin-basic-ui-theme=android] ._1yk3a456 {
  width: 100%;
  justify-content: flex-start;
  padding-left: 1rem;
  font-size: 1.1875rem;
  line-height: 1.5;
  font-weight: bold;
  box-sizing: border-box;
}
._1coppmz1 ._1yk3a456, :root[data-stackflow-plugin-basic-ui-theme=cupertino] ._1yk3a456 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 600;
  font-size: 1rem;
  left: 50%;
  transform: translate(-50%);
  height: var(--stackflow-plugin-basic-ui-app-bar-height);
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
}
._1coppmz0 ._1yk3a457, :root[data-stackflow-plugin-basic-ui-theme=android] ._1yk3a457 {
  padding-left: 0.375rem;
}
._1yk3a458 {
  text-overflow: ellipsis;
  font-size: inherit;
  font-weight: inherit;
}
._1yk3a459 {
  padding: 0 0.5rem;
  margin-left: auto;
}
._1yk3a459:empty {
  display: none;
}
._1coppmz0 ._1yk3a459, :root[data-stackflow-plugin-basic-ui-theme=android] ._1yk3a459 {
  padding: 0 0.5rem 0 0;
}