._1dlf4346 {
  background-color: var(--stackflow-plugin-basic-ui-dim-background-color);
}
._1dlf4347 {
  background-color: var(--stackflow-plugin-basic-ui-background-color);
}
._1dlf4348 {
  transition: var(--_1dlf4340);
}
._1dlf434c {
  transform: translateX(100%);
}
._1dlf434i {
  opacity: 0;
  z-index: var(--_1dlf4341);
}
._1coppmz0 ._1dlf434i, :root[data-stackflow-plugin-basic-ui-theme=android] ._1dlf434i {
  height: 10rem;
  background: linear-gradient(var(--stackflow-plugin-basic-ui-dim-background-color), rgba(0, 0, 0, 0));
}
._1dlf4349 ._1dlf434i {
  opacity: 1;
}
._1dlf434a ._1dlf434i {
  opacity: 1;
}
._1dlf434b ._1dlf434i {
  opacity: 0;
}
._1dlf434c ._1dlf434i {
  opacity: 0;
}
._1dlf434j {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: var(--_1dlf4342);
}
._1dlf434j::-webkit-scrollbar {
  display: none;
}
._1coppmz1 ._1dlf434j, :root[data-stackflow-plugin-basic-ui-theme=cupertino] ._1dlf434j {
  transform: translateX(100%);
}
._1coppmz1 ._1dlf4349 ._1dlf434j, :root[data-stackflow-plugin-basic-ui-theme=cupertino] ._1dlf4349 ._1dlf434j {
  transform: translateX(0);
}
._1coppmz1 ._1dlf434a ._1dlf434j, :root[data-stackflow-plugin-basic-ui-theme=cupertino] ._1dlf434a ._1dlf434j {
  transform: translateX(0);
}
._1coppmz0 ._1dlf434j, :root[data-stackflow-plugin-basic-ui-theme=android] ._1dlf434j {
  opacity: 0;
  transform: translateY(10rem);
}
._1coppmz0 ._1dlf4349 ._1dlf434j, :root[data-stackflow-plugin-basic-ui-theme=android] ._1dlf4349 ._1dlf434j {
  opacity: 1;
  transform: translateY(0);
}
._1coppmz0 ._1dlf434a ._1dlf434j, :root[data-stackflow-plugin-basic-ui-theme=android] ._1dlf434a ._1dlf434j {
  opacity: 1;
  transform: translateY(0);
}
._1dlf434k {
  padding-top: calc(var(--stackflow-plugin-basic-ui-app-bar-height) + constant(safe-area-inset-top));
  padding-top: calc(var(--stackflow-plugin-basic-ui-app-bar-height) + env(safe-area-inset-top));
}
._1dlf434l {
  width: 1.25rem;
  z-index: var(--_1dlf4343);
}
._1dlf434m {
  top: var(--stackflow-plugin-basic-ui-app-bar-height);
  height: calc(100% - var(--stackflow-plugin-basic-ui-app-bar-height));
}